.programBelajar-section {
  text-align: start;

  margin-top: 5vh;
}

.programBelajar-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #333333;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
}

.programBelajar-description {
  font-size: 16px;
  color: #4f4f4f;
  margin-bottom: 30px;
  line-height: 1.5;
  max-width: 100%;
  font-family: "Nunito", sans-serif;
  font-weight: 500;
}

.programBelajar-les-privat {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 26px;
}

.programBelajar-card {
  border-radius: 10px;
  overflow: hidden;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

/* Efek hover */
.programBelajar-card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  transform: scale(1.05);
}

/* 4 items per row on large screens (1280px and above) */
@media (min-width: 1280px) {
  .programBelajar-card {
    width: calc(25% - 20px); /* 4 cards per row, subtracting gap */
  }
}

/* 3 items per row on tablet (between 768px and 1279px) */
@media (min-width: 768px) and (max-width: 1279px) {
  .programBelajar-card {
    width: calc(33.3333% - 20px); /* 3 cards per row, subtracting gap */
  }
}

/* 2 items per row on small tablets (between 480px and 767px) */
@media (max-width: 767px) {
  .programBelajar-card {
    width: calc(50% - 20px); /* 2 cards per row, subtracting gap */
  }
}

/* 1 item per row on mobile (below 480px) */
/* @media (max-width: 479px) {
  .programBelajar-card {
    width: 100%; 
  }
} */

.programBelajar-card img {
  width: 100%;
  height: 100%;
}

@media (max-width: 768px) {
  .programBelajar-title {
    font-size: 20px;
    font-weight: 600;
  }
  .programBelajar-description {
    font-size: 14px;
  }
}
