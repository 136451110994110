.text-with-image-container {
  text-align: center;

  margin-top: 3vh;
  margin-bottom: 2vh;
}

.centered-text {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  color: #333333;
  margin-bottom: 20px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  font-weight: 600;
}

.centered-image img {
  max-width: 100%;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
