/* ScrollToTopButton.css */

.scroll-to-top {
  position: fixed;
  bottom: 100px;
  right: 144px;
  background-color: #3498db;
  border: none;
  z-index: 1000;
  outline: none;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 30px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.scroll-to-top:hover {
  background-color: #2980b9;
  transform: translateY(-5px); /* Tombol akan terangkat sedikit saat hover */
}

.scroll-to-top:active {
  background-color: #1d6a96;
}

/* tablet view */
@media (max-width: 1024px) {
  .scroll-to-top {
    right: 6%;
    bottom: 11%;
    width: 45px;
    height: 45px;
    font-size: 28px;
  }
}

/* Mobile View */

@media (max-width: 768px) {
  .scroll-to-top {
    right: 9%;
    bottom: 13%;
    width: 40px;
    height: 40px;
    font-size: 25px;
  }
}
