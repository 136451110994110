.parent-box-keunggulan {
  margin-top: 50px;
}
.title-keunggulan {
  color: #333333;
  display: flex;
  align-items: center;
  font-size: 30px;
  gap: 10px;
}
.icon-keunggulan {
  width: 60px;
}
.parent-card-keunggulan {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}
.keunggulan-img {
  width: 24%;
  cursor: pointer;
  transition: 0.2s;
}
.keunggulan-img:hover {
  transform: scale(0.9);
  cursor: pointer;
  border-radius: 5px;
}

/* RESPONSIVE */
@media only screen and (max-width: 500px) {
  .keunggulan-img {
    width: 40%;
  }
  .title-keunggulan {
    justify-content: center;
    font-size: 20px;
  }
  .icon-keunggulan {
    width: 35px;
  }
  .parent-box-keunggulan {
    margin-top: 20px;
  }
  .parent-card-keunggulan {
    margin-top: 10px;
  }
}
@media only screen and (max-width: 768px) {
  .keunggulan-img {
    width: 45%;
  }
  .title-keunggulan {
    justify-content: center;
  }
}
