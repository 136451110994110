.fitur-program {
  border-radius: 5px;
  margin-top: 5vh;

  margin-bottom: 5vh;
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  font-family: "Poppins", sans-serif;
}

.title h2 {
  margin: 0;
  color: #333333;
  font-size: 24px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.title .border {
  flex-grow: 1;
  height: 2px;
  background-color: #333;
  margin-left: 10px;
}

.features {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.feature {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature-header {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.icon {
  font-size: 30px;
}

.features h3 {
  font-size: 18px;
  margin: 0;
  font-weight: 600;
  color: #333333;
  font-family: "Poppins", sans-serif;
}

.features p {
  font-size: 16px;
  color: #4f4f4f;
  margin: 0;
  font-family: "Nunito", sans-serif;
  font-weight: 500;
}

/* Efek Hover untuk Card */
.feature:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-color: #e9e9e9;
}
.icon-fitur {
  width: 40px; /* Sesuaikan ukuran */
  height: 40px; /* Sesuaikan ukuran */
  object-fit: contain; /* Pastikan gambar proporsional */
}

/* Media Query untuk Tablet (Ukuran layar 1024px) */
@media (max-width: 1024px) {
  .features {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Media Query untuk Mobile (Ukuran layar di bawah 768px) */
@media (max-width: 768px) {
  .features {
    grid-template-columns: 1fr;
  }
  .title h2 {
    font-size: 20px;
  }
  .features h3 {
    font-size: 16px;
  }
  .features p {
    font-size: 14px;
  }
}
