/* FLOAT WA */
.box-float-main {
  position: fixed;
  bottom: 3%;
  right: 3%;

  z-index: 9998;

  padding: 10px;
  display: grid;
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding-left: 0;
}
.btn-float-tlp {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  background-color: #9b0029;
  border: 1px solid transparent;
  color: white;
  padding: 15px;
  /* padding-left: 6px; */
  border-radius: 100px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 8px 8px;
}
.parent-icon-tlp {
  background-color: #9b0029;
  padding: 6px;
  /* padding-right: 15px;
    padding-left: 15px; */
  border-radius: 100px;
  width: 100px;
  cursor: pointer;
}
.btn-float-wa {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  background-color: #00b33c;
  border: 1px solid transparent;
  color: white;
  padding: 8px;
  /* padding-left: 6px; */
  border-radius: 100px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 8px 8px;
}
.parent-icon-wa {
  background-color: #00b33c;
  padding: 4px;
  /* padding-right: 16px;
    padding-left: 16px; */
  border-radius: 100px;
  width: 100px;
  cursor: pointer;
}
.main-parent-wa {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.icon-wa {
  width: 50px; /* Atur ukuran lebar gambar sesuai keinginan Anda */
  height: 50px; /* Atur ukuran tinggi gambar sesuai keinginan Anda */
}

.label-cta {
  background: rgba(221, 221, 221, 0.87);
  font-size: 13px;
  padding: 8px;
  border-radius: 5px;
  font-weight: 600;
}

@media (max-width: 820px) {
  .box-float-main {
    right: 2%;
    bottom: 3%;
  }
}

@media (max-width: 768px) {
  .box-float-main {
    left: 49%;
    bottom: 2%;
    .icon-wa {
      width: 30px; /* Atur ukuran lebar gambar sesuai keinginan Anda */
      height: 30px; /* Atur ukuran tinggi gambar sesuai keinginan Anda */
    }
  }
  .btn-float-wa {
    padding: 10px;
  }
  .label-cta {
    font-size: 12px;
  }
}
