.container-asal-sekolah-siswa-edumatrix {
  color: white; /* text-white */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5vh;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  border-radius: 0.5rem;
}

.title-section {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.title-asal-sekolah {
  font-size: 28px;
  line-height: 24px;
  color: #333333;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  text-align: center;
  margin-bottom: 2.5rem;
}

.scrollable-content {
  background-color: #ffffff;
  color: #4f4f4f;
  font-family: "Nunito", sans-serif;
  border-radius: 0.5rem;
  overflow: auto;
  font-weight: 600;
  padding: 2vh;
  max-height: 580px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.grid-container-asal-sekolah-siswa-edumatrix {
  display: grid; /* Add display grid */
  grid-template-columns: repeat(1, 1fr); /* Default to 1 column */
  list-style-type: disc; /* Menambahkan bullet berbentuk lingkaran */
  padding-left: 1.5rem; /* Tambahkan jarak kiri untuk bullet */
  margin: 0; /* Atur margin menjadi nol untuk merapikan */
}

@media (min-width: 768px) {
  .grid-container-asal-sekolah-siswa-edumatrix {
    grid-template-columns: repeat(2, 1fr); /* 2 columns on tablet */
  }
}

@media (min-width: 1024px) {
  .grid-container-asal-sekolah-siswa-edumatrix {
    grid-template-columns: repeat(3, 1fr); /* 3 columns on desktop */
  }
}

.grid-item {
  color: #4f4f4f; /* Warna teks */
  font-size: 16px; /* Ukuran font */
  line-height: 1.6; /* Tinggi baris */
}

.grid-container-asal-sekolah-siswa-edumatrix li {
  font-family: "Nunito", sans-serif; /* Font keluarga */
}

/* Style for the scrollbar */
.scrollable-content::-webkit-scrollbar {
  width: 10px; /* Set the width of the scrollbar */
  height: 10px; /* Set the height of the scrollbar (for horizontal scroll) */
}

/* For browsers like Firefox */
.scrollable-content {
  scrollbar-width: thin; /* Make the scrollbar thin */
  scrollbar-color: #f5f5f5 #0b44d9; /* thumb color | track color */
}

@media (max-width: 768px) {
  .title-asal-sekolah {
    font-size: 24px;
  }
  .grid-item {
    font-size: 16px;
  }
}
