/* Header Container */
.header-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: transparent; /* Awal transparan */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  transition: background-color 0.3s ease, backdrop-filter 0.3s ease; /* Tambah efek transisi */
}

/* Saat di-scroll, hanya header yang blur */
.header-container.scrolled {
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px); /* Efek blur di navbar */
  /* -webkit-backdrop-filter: blur(10px); */
}

/* Navigation Container */
.nav-container {
  width: 100%;
  padding-left: 12%;
  padding-right: 12%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}
.button-container {
  display: flex;
  flex-direction: column; /* Atur teks berada di bawah tombol */
  align-items: center; /* Ratakan secara horizontal */
}

@media (max-width: 820px) {
  .nav-container {
    padding-left: 3%; /* Menghapus padding kiri di perangkat mobile */
    padding-right: 3%; /* Menghapus padding kanan di perangkat mobile */
  }
}

@media (max-width: 1024px) {
  .auth-menu {
    margin-top: 0 !important; /* Pastikan margin di-reset */
    display: flex !important; /* Pastikan menjadi flex */
    align-items: center !important;
    justify-content: center !important;
    flex-direction: row !important;
    gap: 5px !important;
  }
  .nav-container {
    padding-left: 4%;
    padding-right: 4%;
  }
}

/* Logo Container */
.logo-container {
  display: flex;
  align-items: center;
  gap: 12px;
  z-index: 999;
}

/* Menu Desktop */
.desktop-menu {
  display: none;
}

@media (min-width: 1024px) {
  .desktop-menu {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

/* Mobile Menu */
.mobile-menu {
  display: block;
}

@media (min-width: 1024px) {
  .mobile-menu {
    display: none;
  }
}

/* Auth Menu */
.auth-menu {
  margin-top: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: "Nunito", sans-serif; /* Atur ke kolom agar teks berada di bawah button */
}

/* Sign-in Button */
/* Teks di bawah tombol */
.registration-info {
  font-size: 12px;
  margin: 0;
  color: #4f4f4f; /* Warna abu-abu lembut */
}

.sign-in-button {
  background-color: #1417e5; /* Solid background for better contrast */
  color: #ffffff; /* White text for contrast */
  text-decoration: none;
  padding: 8px 14px;
  border-radius: 12px;
  border: none;
  font-size: 16px;
  font-weight: 600;
  transition: background-color 0.3s ease, transform 0.2s ease;
  cursor: pointer;
  outline: none;
  margin-top: 10px;
}

.sign-in-button:hover {
  background-color: #1f46c8; /* Slightly darker on hover */
}

.sign-in-button:focus {
  outline: none;
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.5);
}
/* Efek aktif (ketika tombol ditekan) */
.sign-in-button:active {
  transform: translateY(1px); /* Sedikit menekan tombol ke bawah */
}

/* Mobile Menu */
.mobile-menu {
  display: block;
}

@media (min-width: 1024px) {
  .mobile-menu {
    display: none;
  }
}

.logo-nav {
  height: 3em;
  will-change: filter;
  transition: filter 300ms;
}
.logo-nav:hover {
  filter: drop-shadow(0 0 2em #646cffaa);
}
.logo-nav.react:hover {
  filter: drop-shadow(0 0 2em #61dafbaa);
}
