.program-les-privat-container {
  margin-top: 4vh;
}

.program-title {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #333333;
  font-family: "Poppins", sans-serif;
  text-align: center;
}

.programs-les-private {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.program-les-private {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Shadow */
}

.program-les-private:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2); /* Shadow effect saat hover */
}

.feature-header-les-private {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.icon-program {
  width: 60px;

  height: 60px;
}

.program-les-private h3 {
  font-size: 20px;
  margin: 0;
  font-weight: 500;
  color: #333;
  font-family: "Poppins", sans-serif;
}

.programs-les-private p {
  font-size: 16px;
  color: #4f4f4f;
  margin: 0;
  font-family: "Nunito", sans-serif;
  font-weight: 400;
}

/* Media Query untuk Tablet (Ukuran layar 1024px) */
@media (max-width: 1024px) {
  .programs-les-private {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Media Query untuk Mobile (Ukuran layar di bawah 768px) */
@media (max-width: 768px) {
  .programs-les-private {
    grid-template-columns: 1fr;
  }
  .program-title {
    font-size: 24px;
  }
  .program-les-private h3 {
    font-size: 18px;
  }
  .program-les-private p {
    font-size: 14px;
  }
}
