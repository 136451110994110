/* LesPrivateMahasiswaList.css */

.les-private-bahasa-list-container {
  padding: 0;
  margin-top: 1vh;
  margin-bottom: 1vh;
}

.les-private-bahasa-list-container h2 {
  font-size: 1.1rem;
  color: #333333;
  margin-top: 2rem;
  margin-bottom: 1.8rem;
  font-family: "Nunito", sans-serif;
}

.subject-list-les-private-bahasa {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: grid;
}

.subject-list-les-private-bahasa-item {
  display: flex;
  align-items: flex-start; /* Align items to the start */
  gap: 20px; /* Spacing between icon and text */
  margin-bottom: 8px;
}

.subject-list-les-private-bahasa-icon {
  flex-shrink: 0; /* Prevent icon from shrinking */
  width: 24px; /* Fixed width for icon */
  height: 24px; /* Fixed height for icon */
  color: #4caf50;
}

.subject-text {
  flex: 1;
  font-weight: 500;
  font-family: "Nunito", sans-serif;
  color: #333333; /* Ganti warna teks sesuai keinginan, misalnya biru */
}
