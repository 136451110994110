.container-halaman-hero-home {
  margin-top: 28px;
  min-height: 80vh;
  /* background-color: #00139b; */
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 10px;
}

.content-hero-home {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #333333;
  height: auto;
}
.title-halaman-hero-home {
  font-size: 36px; /* Ukuran font judul */
  font-weight: 600; /* Membuat judul menjadi tebal */
  margin-bottom: 15px; /* Spasi di bawah judul */
  text-align: left; /* Mengatur teks judul rata kiri */
  line-height: 1.2; /* Jarak antar garis teks */
  color: #333333; /* Warna teks judul */
  font-family: "Poppins", sans-serif;
}
.isi-content-hero-home {
  flex: 0 0 50%;
  /* margin-right: 5px; */
}

/* 4F4A45 */

.rumah-adat-hero-home {
  flex: 0 0 50%;
  width: 50%;
  height: auto;
}

.btn-daftar-sekarang,
.btn-learn-more {
  margin-top: 20px;
  padding: 12px 24px;
  background-color: #1417e5;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  font-family: "Nunito", sans-serif;
  transition: background-color 0.3s ease;
}

.btn-daftar-sekarang:hover,
.btn-learn-more:hover {
  background-color: #ff9900;
}

.btn-learn-more {
  background-color: #c1c7d7;
  color: #00139b;
  margin-left: 10px;
}

/* Style for child-paragraf-hero-home */
.child-paragraf-hero-home {
  font-size: 16px;
  color: #4f4f4f;
  line-height: 1.4;
  margin-bottom: 20px;
  font-weight: 600;
  font-family: "Nunito", sans-serif;
}

.highlight-yellow {
  color: #333333; /* Warna kuning */
}

/* Media query for mobile devices */
@media (max-width: 820px) {
  .content-hero-home {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
  }
  .title-halaman-hero-home {
    font-size: 23px; /* Ukuran font lebih kecil untuk layar kecil */
  }
  .rumah-adat-hero-home {
    width: 100%;
    height: auto;
  }

  .child-paragraf-hero-home {
    font-size: 14px;
  }
  .isi-content-hero-home {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .btn-daftar-sekarang,
  .btn-learn-more {
    width: 100%;
    text-align: center;
    margin-left: 0;
  }
}
