.menu-item {
  position: relative;
  list-style: none;
}

.menu-link {
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 500;
  padding: 4px 8px;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  text-decoration: none;
  color: white; /* Tambahkan warna font */
  font-family: "Nunito", sans-serif;
}

.menu-link:hover {
  color: #011cde; /* Ubah warna font saat hover */
}

/* Tambahkan kelas untuk link aktif */
.menu-link.active {
  color: #011cde; /* Warna font untuk link aktif */
}

.chevron-icon {
  margin-top: 2px;
  transition: transform 0.2s ease-in-out;
}

.rotate-180 {
  transform: rotate(180deg);
}

.sub-menu {
  position: absolute;
  top: 110%; /* Submenu muncul tepat di bawah elemen parent */
  left: 0;

  z-index: 999; /* Pastikan submenu ada di atas elemen lain */
  padding: 16px;
  border-radius: 6px;
  backdrop-filter: blur(4px); /* Efek blur pada background */
  background-color: rgba(
    1,
    28,
    222,
    0.8
  ); /* Latar belakang dengan transparansi */
  display: none; /* Mulanya disembunyikan */
  width: 400px; /* Batasi lebar submenu */
  grid-gap: 20px;
}

.grid {
  display: grid;
  gap: 20px;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.menu-box {
  cursor: pointer;
}

.menu-heading {
  font-size: 14px;
  color: #737373;
  margin-bottom: 8px;
}

.menu-content {
  display: flex;
  align-items: center;
  gap: 16px;
  text-decoration: none;
}

.icon-box {
  background-color: rgba(255, 255, 255, 0.05);
  padding: 8px;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
}

.menu-box:hover .icon-box {
  background-color: white;
  color: #1a1a1a;
}

@media (max-width: 768px) {
  .sub-menu {
    width: auto;
  }

  .grid {
    gap: 16px;
  }
}
.menu-item {
  position: relative;
  list-style: none;
}

.menu-link {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  text-decoration: none;
  color: #1a1a1a; /* Tambahkan warna font */
}

.menu-link:hover {
  background-color: rgba(255, 255, 255, 0.05);
}

.chevron-icon {
  margin-top: 2px;
  transition: transform 0.2s ease-in-out;
}

.rotate-180 {
  transform: rotate(180deg);
}

.sub-menu {
  position: absolute;
  top: 100%; /* Submenu muncul tepat di bawah elemen parent */
  left: 0;
  z-index: 999; /* Pastikan submenu ada di atas elemen lain */
  padding: 16px;
  border-radius: 6px;
  backdrop-filter: blur(4px); /* Efek blur pada background */
  background-color: rgba(
    1,
    28,
    222,
    0.8
  ); /* Latar belakang dengan transparansi */
  display: none; /* Mulanya disembunyikan */
  width: 500px; /* Batasi lebar submenu */
  height: 420px;
  grid-gap: 20px;
}

.grid {
  display: grid;
  gap: 20px;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-auto-rows: auto; /* Pastikan tinggi item otomatis mengikuti konten */
}

.menu-box {
  cursor: pointer;
  min-width: 150px; /* Set minimum width untuk memastikan elemen tidak terlalu kecil */
  word-wrap: break-word; /* Membantu memecah kata panjang */
}

.menu-heading {
  font-size: 14px;
  color: #737373;
  margin-bottom: 8px;
}

.menu-content {
  display: flex;
  align-items: center;
  gap: 16px;
}

.icon-box {
  background-color: rgba(255, 255, 255, 0.05);
  padding: 8px;
  color: #7fffd4;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
}

.menu-box:hover .icon-box {
  background-color: white;
  color: #1a1a1a;
}

.submenu-name {
  font-weight: 600;
  margin-bottom: -10px;
  color: #e0e0e0;
  font-family: "Nunito", sans-serif;
}

.submenu-desc {
  font-size: 16px;
  font-weight: 700;
  color: #f0f0f0;
  font-family: "Nunito", sans-serif;
}

@media (max-width: 768px) {
  .sub-menu {
    width: auto;
  }

  .grid {
    gap: 16px;
  }
}
