.program-options {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin-top: 3vh;

  margin-bottom: 2vh;
}

.option {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.icon {
  font-size: 30px;
  margin-bottom: 10px;
}

.program-options h3 {
  font-size: 23px;
  margin-bottom: 10px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #333333;
}

.program-options p {
  font-size: 17px;
  color: #4f4f4f;
  font-family: "Nunito", sans-serif;
  font-weight: 500;
}

@media (max-width: 768px) {
  .program-options {
    grid-template-columns: 1fr;
  }
  .program-options h3 {
    font-size: 18px;
    font-weight: 600;
  }
  .program-options p {
    font-size: 16px;
  }
  .program-options p {
    text-align: left;
  }
}
