/* TestimonialSection.css */
.matrix-container {
  display: flex;
  justify-content: space-between;
  gap: 30px; /* Meningkatkan jarak antar kontainer */
  margin-top: 5vh;
}

.matrix-features,
.testimonial-container,
.subject-container {
  width: 48%;
}

.matrix-features h2,
.testimonial-container h2,
.subject-container h2 {
  position: relative;
  margin-bottom: 10px;
  padding-bottom: 10px;
  color: #333333;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.matrix-features h2::after,
.subject-container h2::after,
.testimonial-container h2::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: #333333;
}

.feature-list {
  display: flex;
  flex-direction: column;
}

.feature-item {
  display: flex;
  align-items: center;
}

.feature-icon {
  background-color: #4caf50; /* Green background */
  border-radius: 100%;
  padding: 4px;

  margin-right: 10px;
}

.feature-icon svg {
  font-size: 18px;
  color: #fff;
}

.feature-item h3 {
  font-size: 14px;
  color: #333;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.testimonial-item {
  border-radius: 10px;
  padding: 10px;
  background-color: #fbfbfb;
  margin-bottom: 20px; /* Menambahkan jarak antar testimonial */
}

.testimonial-item p {
  font-size: 16px;
  color: #666;
  font-family: "Nunito", sans-serif;
  margin-bottom: 10px;
  font-weight: 500;
}

.testimonial-author {
  display: flex;
  align-items: center;
}

.testimonial-author img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.testimonial-author h3 {
  font-size: 14px;
  color: #333;
  font-weight: 500;
}

.subject-container {
  border-radius: 10px;
}

.subject-container h2 {
  margin-bottom: 10px;
}

.subject-container p {
  font-size: 16px;
  color: #666;

  font-family: "Nunito", sans-serif;
  margin-bottom: 15px;
}

.subject-list {
  list-style-type: disc;
}

.subject-list li {
  font-size: 16px;
  color: #333;
  font-family: "Nunito", sans-serif;
  margin-bottom: 10px;
  font-weight: 600;
}

@media (max-width: 768px) {
  .matrix-container {
    flex-direction: column;
  }

  .matrix-features,
  .testimonial-container,
  .subject-container {
    width: 100%;
    margin-bottom: 20px;
  }
  .feature-item h3 {
    font-size: 13px;
  }
}
