.about-us {
  color: #333333;
}

.about-us-header {
  text-align: center;
  margin-bottom: 40px;
  margin-top: 80px;
}

.about-us-header h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 2.5em;
  margin-bottom: 10px;
}

.about-us-header p {
  font-size: 1.2em;
  color: #666;
  font-weight: 500;
  font-family: "Nunito", sans-serif;
}

.about-us-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.about-us-image img {
  max-width: 100%;
  height: 100%;
  border-radius: 8px;
}

.about-us-info {
  width: 100%;

  text-align: center;
}

.about-us-info h2 {
  font-size: 2em;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  margin-bottom: 15px;
}

.about-us-info p {
  font-size: 1.1em;
  font-weight: 500;
  font-family: "Nunito", sans-serif;
  line-height: 1.6;
  color: #4f4f4f;
  margin-bottom: 20px;
}
