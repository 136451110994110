/* LesPrivateSimakUIPrograms.css */

.section-simak-SBMPTN-program {
  margin: 0 auto;
  padding: 0rem 1rem;
  color: #333333; /* Text color gray-800 dalam Tailwind CSS */
}

.section-simak-SBMPTN-program p {
  margin-bottom: 0.2rem; /* mb-4 dalam Tailwind CSS */
}

.space-y-6 > div {
  margin-bottom: 1rem; /* space-y-6 dalam Tailwind CSS */
}

.space-y-6 h3 {
  font-size: 1rem; /* Text-lg dalam Tailwind CSS */
  font-weight: 500;
  color: #333333; /* Text color gray-800 dalam Tailwind CSS */
}

.space-y-6 p {
  margin-left: 1rem; /* ml-4 dalam Tailwind CSS */
  margin-top: 0.1rem; /* mt-2 dalam Tailwind CSS */
}
