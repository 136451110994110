.les-private-UI-list-container {
  padding: 0;
  margin-top: 12vh;
  margin-bottom: 10vh;
}

.les-private-UI-list-container h2 {
  font-size: 1.1rem;
  color: #333333;
  margin-top: 2rem;
  margin-bottom: 1.8rem;
  font-family: "Nunito", sans-serif;
}

.subject-list-les-private-UI {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: grid;
}

.subject-list-les-private-UI-item {
  display: flex;
  align-items: flex-start; /* Align items to the start */
  gap: 8px; /* Spacing between icon and text */
  margin-bottom: 8px;
}

.subject-list-les-private-UI-icon {
  flex-shrink: 0; /* Prevent icon from shrinking */
  width: 24px; /* Fixed width for icon */
  height: 24px; /* Fixed height for icon */
  color: #4caf50;
}

.subject-text {
  flex: 1;
  font-weight: 600;
}

/* Media queries for responsiveness */
