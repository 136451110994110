.online-les-options {
  text-align: center;

  margin-top: 3vh;
  margin-bottom: 2vh;
}

.title-description {
  margin-bottom: 30px;
}

.title-description-online-les-option h2 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #333333;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.title-description-online-les-option p {
  font-size: 16px;
  color: #4f4f4f;
  font-family: "Nunito", sans-serif;
  font-weight: 400;
}

.options {
  display: grid;
  gap: 20px;
  justify-content: center; /* Center elements horizontally */
}

.online-les-option {
  width: 280px;
  height: 280px;
  background-color: #f5f5f5;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  border: #f6f6f6 6px solid;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition */
}

.online-les-option img {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  max-width: none;
}

.online-les-option:hover {
  transform: scale(1.05); /* Slightly enlarge the box */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Enhance the shadow */
}

/* Mobile */
@media (max-width: 768px) {
  .options {
    grid-template-columns: 1fr; /* 1 image per row on mobile */
  }
  .title-description-online-les-option h2 {
    font-size: 20px;
    font-weight: 600;
  }
}

/* Tablet */
@media (min-width: 769px) and (max-width: 1024px) {
  .options {
    grid-template-columns: repeat(2, 1fr); /* 2 images per row on tablet */
    justify-content: center; /* Center images horizontally */
  }

  .online-les-option {
    margin: 0 auto;
  }
}

/* Desktop */
@media (min-width: 1025px) {
  .options {
    grid-template-columns: repeat(3, 1fr); /* 3 images per row on desktop */
    justify-content: center; /* Center images horizontally */
  }
}
.online-les-options {
  text-align: center;

  margin-top: 80px;
  margin-bottom: 15px;
}

.title-description {
  margin-bottom: 30px;
}

.title-description h2 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #333333;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.title-description p {
  font-size: 16px;
  color: #666;
  font-family: "Nunito", sans-serif;
  font-weight: 400;
}

.options {
  display: grid;
  gap: 20px;
  justify-content: center; /* Center elements horizontally */
}

.online-les-option {
  width: 280px;
  height: 280px;
  background-color: #f5f5f5;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  border: #f6f6f6 6px solid;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition */
}

.online-les-option img {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  max-width: none;
}

.online-les-option:hover {
  transform: scale(1.05); /* Slightly enlarge the box */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Enhance the shadow */
}

/* Mobile */
@media (max-width: 768px) {
  .options {
    grid-template-columns: 1fr; /* 1 image per row on mobile */
  }
}

/* Tablet */
@media (min-width: 769px) and (max-width: 1024px) {
  .options {
    grid-template-columns: repeat(2, 1fr); /* 2 images per row on tablet */
    justify-content: center; /* Center images horizontally */
  }

  .online-les-option {
    margin: 0 auto;
  }
}

/* Desktop */
@media (min-width: 1025px) {
  .options {
    grid-template-columns: repeat(3, 1fr); /* 3 images per row on desktop */
    justify-content: center; /* Center images horizontally */
  }
}
